body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1440px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 1200px) {
  .home-page-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .home-page-wrapper .home-page {
    height: 100%;
    max-width: 1280px;
    position: relative;
    margin: auto;
    will-change: transform;
  }
  .home-page-wrapper .title-wrapper > h1,
  .home-page-wrapper > h1 {
    font-size: 32px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 16px;
  }
  .home-page-wrapper .title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.one {
  position: fixed;
  top: 90%;
  left: 0;
  width: 100vw;
  height: 5rem;
  transition: 0.5s;
  z-index: 3000;
}
.one1 {
  position: fixed;
  top: 0%;
  width: 1440px;
  left: 0;
  right: 0;
  height: 100vh;
  margin: 0 auto;
}
.one1 .two {
  position: relative;
  z-index: 1000;
  top: 60px;
  left: 70px;
  transition: 0.1s;
  touch-action: none;
}
.icp {
  color: #afaaaa;
  position: fixed;
  top: 96vh;
  text-align: center;
  font-size: 9px;
  width: 100%;
}
.header0 {
  display: flex;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 0px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu > .ant-menu {
  color: black;
  background: transparent;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a {
  color: black;
}
.header0-menu > .ant-menu a:hover {
  color: #1890ff;
}
.header0 .ant-menu-item-selected a {
  color: #1890ff;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .one {
    position: fixed;
    top: 87%;
    left: 0;
    width: 100vw;
    height: 5rem;
    transition: 0.5s;
    z-index: 3000;
  }
  .one1 {
    position: fixed;
    top: 0%;
    width: 1440px;
    left: 0;
    right: 0;
    height: 100vh;
    margin: 0 auto;
  }
  .one1 .two {
    position: relative;
    z-index: 1000;
    top: 30px;
    left: 30px;
    transition: 0.1s;
    touch-action: none;
  }
  .icp {
    color: #afaaaa;
    position: fixed;
    top: 95vh;
    text-align: center;
    font-size: 9px;
    width: 100%;
  }
  .header0 {
    display: flex;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
  }
  .header0 .home-page {
    padding: 0 24px;
  }
  .header0-logo {
    display: inline-block;
    position: relative;
    width: 0px;
    line-height: 64px;
  }
  .header0-logo img {
    vertical-align: middle;
    display: inline-block;
  }
  .header0-logo a {
    display: block;
  }
  .header0-menu {
    float: right;
  }
  .header0-menu > .ant-menu {
    font-size: 12px;
    color: black;
    background: transparent;
    height: 64px;
    border-bottom-color: transparent;
    position: relative;
  }
  .header0-menu > .ant-menu a {
    color: black;
  }
  .header0-menu > .ant-menu a:hover {
    color: #1890ff;
  }
  .header0 .ant-menu-item-selected a {
    color: #1890ff;
  }
}
@media screen and (max-width: 767px) {
  .one {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 5rem;
    transition: 0.5s;
    z-index: 3000;
  }
  .one1 {
    position: fixed;
    top: 0%;
    width: 1440px;
    left: 0;
    right: 0;
    height: 100vh;
    margin: 0 auto;
  }
  .one1 .two {
    position: relative;
    z-index: 1000;
    top: 20px;
    left: 20px;
    transition: 0.1s;
    touch-action: none;
  }
  .header0 {
    display: block;
  }
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: black;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
    background: #001529;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: #fff;
  }
  .header0 .ant-menu a:hover {
    color: #fff;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: #fff;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.content4-wrapper {
  min-height: 720px;
  background: #fafafa;
}
.content4-wrapper .content4 {
  height: 100%;
  overflow: hidden;
}
.content4-wrapper .content4-video {
  border-radius: 4px;
  overflow: hidden;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.content4-wrapper .content4-video video {
  display: block;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .content4-wrapper {
    min-height: 350px;
  }
  .content4-wrapper .content4 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content4-wrapper .content4-video {
    top: 15%;
    background: url("https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg") no-repeat center;
    background-size: cover;
  }
}
.content6-wrapper {
  min-height: 100vh;
}
.content6-wrapper .content6 {
  height: 100%;
  padding: 0 24px;
}
.content6-wrapper .content6 .ImageIcon {
  left: 100px;
}
.content6-wrapper .content6-img1 {
  height: 100%;
  transform-origin: top;
}
.content6-wrapper .content6-img1 .span {
  display: block;
  position: fixed;
  top: 350px;
  left: 980px;
}
.content6-wrapper .content6-img1 .span img {
  display: block;
}
.content6-wrapper .content6-img1 .span5 {
  display: block;
  position: fixed;
  top: 200px;
  left: 150px;
}
.content6-wrapper .content6-img1 .span5 img {
  display: block;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .content6-wrapper {
    min-height: 100vh;
  }
  .content6-wrapper .content6 {
    height: 100%;
    padding: 0 24px;
  }
  .content6-wrapper .content6 .ImageIcon {
    left: 100px;
  }
  .content6-wrapper .content6-img1 {
    height: 100%;
    transform-origin: top;
  }
  .content6-wrapper .content6-img1 .span {
    display: block;
    position: fixed;
    top: 200px;
    left: 780px;
  }
  .content6-wrapper .content6-img1 .span img {
    width: 150px;
    display: block;
  }
  .content6-wrapper .content6-img1 .span5 {
    display: block;
    position: fixed;
    top: 100px;
    left: 200px;
  }
  .content6-wrapper .content6-img1 .span5 img {
    width: 500px;
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .content6-wrapper {
    min-height: 100vh;
  }
  .content6-wrapper .content6-img1 {
    height: 400px;
    transform-origin: top;
  }
  .content6-wrapper .content6-img1 .span {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 0px;
    left: 10000px;
    width: 100%;
  }
  .content6-wrapper .content6-img1 .span5 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: -130px;
    left: 0px;
    width: 100%;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.content1-wrapper {
  min-height: 100vh;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img1 {
  height: 100%;
  transform-origin: top;
}
.content1-wrapper .content1-img1 .span {
  display: block;
  position: fixed;
  top: 482px;
  left: 185px;
}
.content1-wrapper .content1-img1 .span img {
  display: block;
}
.content1-wrapper .content1-img1 .span1 {
  display: block;
  position: fixed;
  top: 360px;
  left: 330px;
}
.content1-wrapper .content1-img1 .span1 img {
  display: block;
}
.content1-wrapper .content1-img1 .span2 {
  display: block;
  position: fixed;
  width: 116px;
  height: 89px;
  top: 530px;
  left: 460px;
}
.content1-wrapper .content1-img1 .span2 img {
  display: block;
}
.content1-wrapper .content1-img1 .span3 {
  display: block;
  position: fixed;
  width: 118px;
  height: 101px;
  top: 430px;
  left: 940px;
}
.content1-wrapper .content1-img1 .span3 img {
  display: block;
}
.content1-wrapper .content1-img1 .span4 {
  display: block;
  position: fixed;
  top: 372px;
  left: 1116px;
}
.content1-wrapper .content1-img1 .span4 img {
  display: block;
}
.content1-wrapper .content1-img1 .span5 {
  display: block;
  position: fixed;
  top: 510px;
  left: 1160px;
}
.content1-wrapper .content1-img1 .span5 img {
  display: block;
}
.content1-wrapper .content1-img1 .span6 {
  display: block;
  position: fixed;
  top: 300px;
  left: 590px;
}
.content1-wrapper .content1-img1 .span6 img {
  display: block;
}
.content1-wrapper .content1-img1 .span7 {
  display: block;
  position: fixed;
  top: 450px;
  left: 0px;
}
.content1-wrapper .content1-img1 .span7 img {
  display: block;
}
.content1-wrapper .content1-text .ImageIcon {
  position: relative;
  display: inline-flex;
  top: 100px;
  left: 450px;
}
.content1-wrapper .content1-text .content1-title {
  color: #696969;
  margin-top: 0px;
  margin-left: 10px;
  font-size: 20px ;
  line-height: 25px;
  width: 600px;
}
.content1-wrapper .content1-text .content1-content {
  color: #9c9c9c;
  position: relative;
  text-align: center;
  font-size: 14px;
  top: 120px;
  left: 465px;
  width: 500px;
}
.content1-wrapper .content1-text .button1 {
  position: relative;
  text-align: center;
  top: 140px;
  left: 660px;
  width: 100px;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .content1-wrapper {
    min-height: 100vh;
  }
  .content1-wrapper .content1 {
    height: 100%;
    padding: 0 24px;
  }
  .content1-wrapper .content1-img1 {
    height: 100%;
    transform-origin: top;
  }
  .content1-wrapper .content1-img1 .span {
    display: block;
    position: fixed;
    top: 310px;
    left: 210px;
  }
  .content1-wrapper .content1-img1 .span img {
    width: 70px;
    display: block;
  }
  .content1-wrapper .content1-img1 .span1 {
    display: block;
    position: fixed;
    top: 200px;
    left: 330px;
  }
  .content1-wrapper .content1-img1 .span1 img {
    width: 40px;
    display: block;
  }
  .content1-wrapper .content1-img1 .span2 {
    display: block;
    position: fixed;
    width: 116px;
    height: 89px;
    top: 300px;
    left: 430px;
  }
  .content1-wrapper .content1-img1 .span2 img {
    width: 80px;
    display: block;
  }
  .content1-wrapper .content1-img1 .span3 {
    display: block;
    position: fixed;
    width: 118px;
    height: 101px;
    top: 280px;
    left: 830px;
  }
  .content1-wrapper .content1-img1 .span3 img {
    width: 40px;
    display: block;
  }
  .content1-wrapper .content1-img1 .span4 {
    display: block;
    position: fixed;
    top: 230px;
    left: 920px;
  }
  .content1-wrapper .content1-img1 .span4 img {
    width: 80px;
    display: block;
  }
  .content1-wrapper .content1-img1 .span5 {
    display: block;
    position: fixed;
    top: 300px;
    left: 1010px;
  }
  .content1-wrapper .content1-img1 .span5 img {
    width: 100px;
    display: block;
  }
  .content1-wrapper .content1-img1 .span6 {
    display: block;
    position: fixed;
    top: 150px;
    left: 560px;
  }
  .content1-wrapper .content1-img1 .span6 img {
    width: 200px;
    display: block;
  }
  .content1-wrapper .content1-img1 .span7 {
    display: block;
    position: fixed;
    top: 250px;
    left: 0px;
  }
  .content1-wrapper .content1-img1 .span7 img {
    display: block;
  }
  .content1-wrapper .content1-text .ImageIcon {
    position: relative;
    display: inline-flex;
    top: 80px;
    left: 350px;
  }
  .content1-wrapper .content1-text .content1-title {
    color: #696969;
    margin-top: 0px;
    margin-left: 10px;
    font-size: 16px ;
    line-height: 25px;
    width: 600px;
    text-align: center;
  }
  .content1-wrapper .content1-text .content1-content {
    position: relative;
    text-align: center;
    font-size: 12px ;
    top: 95px;
    left: 350px;
    width: 600px;
  }
  .content1-wrapper .content1-text .button1 {
    position: relative;
    text-align: center;
    top: 110px;
    left: 600px;
    width: 100px;
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    min-height: 1200px;
  }
  .content1-wrapper .content1-img1 {
    height: 100%;
    transform-origin: top;
  }
  .content1-wrapper .content1-img1 .span {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 400px;
    left: 0px;
    width: 50%;
  }
  .content1-wrapper .content1-img1 .span1 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 400px;
    left: 0px;
    width: 50%;
  }
  .content1-wrapper .content1-img1 .span2 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 420px;
    left: 0px;
    width: 50%;
  }
  .content1-wrapper .content1-img1 .span3 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 420px;
    left: 0px;
    width: 50%;
  }
  .content1-wrapper .content1-img1 .span4 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 440px;
    left: 0px;
    width: 50%;
  }
  .content1-wrapper .content1-img1 .span5 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 440px;
    left: 0px;
    width: 50%;
  }
  .content1-wrapper .content1-img1 .span6 {
    position: relative;
    display: inline-flex;
    display: flex;
    text-align: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 100px;
    left: 0;
  }
  .content1-wrapper .content1-img1 .span6 img {
    display: block;
  }
  .content1-wrapper .content1-text .ImageIcon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 100px;
    left: 0px;
    width: 100%;
  }
  .content1-wrapper .content1-text .content1-title {
    color: black;
    margin-top: 0px;
    margin-left: 10px;
    font-size: 15px ;
    line-height: 25px;
    text-align: center;
    width: 100%;
  }
  .content1-wrapper .content1-text .content1-content {
    display: inline-flex;
    justify-content: center;
    text-align: center;
    position: relative;
    top: 0px;
    margin-top: 120px;
    left: 0px;
    width: 100%;
  }
  .content1-wrapper .content1-text .button1 {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
    top: 0px;
    left: 35%;
    width: 30%;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.content11-wrapper {
  min-height: 100vh;
}
.content11-wrapper .content11 {
  height: 100%;
  padding: 0 24px;
}
.content11-wrapper .content11 .ImageIcon {
  left: 100px;
}
.content11-wrapper .content11-img1 {
  height: 100%;
  transform-origin: top;
}
.content11-wrapper .content11-img1 span {
  display: flex;
  position: relative;
  top: 187px;
  left: 200px;
}
.content11-wrapper .content11-img1 span img {
  display: block;
}
.content11-wrapper .content11-text {
  padding: 0 32px;
  top: 300px ;
  position: relative;
  left: -100px;
  height: 100%;
}
.content11-wrapper .content11-text .content11-content,
.content11-wrapper .content11-text .content11-title {
  color: #9c9c9c;
  width: 240px;
  margin-top: 10px;
  font-size: 14px ;
  line-height: 20px;
  position: relative !important;
}
.content11-wrapper .content11-text .content11-title {
  font-size: 20px ;
  font-weight: normal;
  color: #696969;
  margin-top: 0px;
}
.content11-wrapper .content11-text .content {
  color: #929292;
  margin-top: 20px;
}
.content11-wrapper .content11-text .ImageIcon {
  padding-right: 10px;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .content11-wrapper {
    min-height: 100vh;
  }
  .content11-wrapper .content11 {
    height: 100%;
    padding: 0 24px;
  }
  .content11-wrapper .content11 .ImageIcon {
    left: 100px;
  }
  .content11-wrapper .content11-img1 {
    height: 100%;
    transform-origin: top;
  }
  .content11-wrapper .content11-img1 span {
    display: flex;
    position: relative;
    top: 120px;
    left: 300px;
  }
  .content11-wrapper .content11-img1 span img {
    width: 400px;
    display: block;
  }
  .content11-wrapper .content11-text {
    padding: 0 32px;
    top: 180px ;
    position: relative;
    height: 100%;
    left: -100px;
  }
  .content11-wrapper .content11-text .content11-content,
  .content11-wrapper .content11-text .content11-title {
    color: #9c9c9c;
    width: 240px;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
    position: relative !important;
  }
  .content11-wrapper .content11-text .content11-title {
    font-size: 16px ;
    font-weight: normal;
    color: #696969;
    margin-top: 0px;
  }
  .content11-wrapper .content11-text .content {
    color: #929292;
    margin-top: 12px;
  }
  .content11-wrapper .content11-text .ImageIcon {
    padding-right: 10px;
  }
  .content11-wrapper .content11-text .button1 {
    position: relative;
    font-size: 100px;
  }
}
@media screen and (max-width: 767px) {
  .content11-wrapper {
    min-height: 1200px;
  }
  .content11-wrapper .content11 {
    height: 100%;
    padding: 0 24px;
  }
  .content11-wrapper .content11 .ImageIcon {
    left: 100px;
  }
  .content11-wrapper .content11-img1 {
    height: 300px;
    transform-origin: top;
  }
  .content11-wrapper .content11-img1 span {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    top: 100px;
    left: 0px;
  }
  .content11-wrapper .content11-img1 span img {
    display: block;
  }
  .content11-wrapper .content11-text {
    padding: 0;
    top: 300px ;
    position: relative;
    left: 0px;
    height: 100%;
  }
  .content11-wrapper .content11-text .content11-content,
  .content11-wrapper .content11-text .content11-title {
    color: #9c9c9c;
    width: 100%;
    margin-top: 10px;
    font-size: 14px ;
    line-height: 20px;
    text-align: center;
    position: relative !important;
  }
  .content11-wrapper .content11-text .content11-title {
    font-size: 20px ;
    font-weight: normal;
    color: #696969;
    margin-top: 0px;
    text-align: center;
  }
  .content11-wrapper .content11-text .content {
    color: #929292;
    margin-top: 20px;
  }
  .content11-wrapper .content11-text .ImageIcon {
    padding-right: 10px;
  }
  .content11-wrapper .content11-text .button1 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    top: 20px;
    width: 100%;
  }
}

.App {
  text-align: center;
}
.aaa{
  background-color: #f6f6f6;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f6f6f6;
}

.App-link {
  color: black;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.content20-wrapper {
  min-height: 100vh;
}
.content20-wrapper .content20 {
  height: 100%;
  padding: 0 24px;
}
.content20-wrapper .content20 .ImageIcon {
  left: 100px;
}
.content20-wrapper .content20-img1 {
  height: 100%;
  top: -80px;
}
.content20-wrapper .content20-img1 .span {
  display: flex;
  position: fixed;
  top: 461px;
  left: 251px;
}
.content20-wrapper .content20-img1 .span img {
  display: block;
}
.content20-wrapper .content20-img1 .span1 {
  display: flex;
  position: fixed;
  top: 353px;
  left: 407px;
}
.content20-wrapper .content20-img1 .span1 img {
  display: block;
}
.content20-wrapper .content20-img1 .span2 {
  display: flex;
  position: fixed;
  top: 227px;
  left: 984px;
}
.content20-wrapper .content20-img1 .span2 img {
  display: block;
}
.content20-wrapper .content20-img1 .span3 {
  display: flex;
  position: fixed;
  top: 390px;
  left: 1061px;
}
.content20-wrapper .content20-img1 .span3 img {
  display: block;
}
.content20-wrapper .content20-img1 .span4 {
  display: flex;
  position: fixed;
  top: 227px;
  left: 238px;
}
.content20-wrapper .content20-img1 .span4 img {
  display: block;
}
.content20-wrapper .content20-text {
  padding: 0 32px;
  top: 500px ;
  left: 500px;
  height: 100%;
  position: relative;
}
.content20-wrapper .content20-text .content20-content,
.content20-wrapper .content20-text .content20-title {
  text-align: center;
  color: #696969;
  width: 400px;
  margin-top: 10px;
  font-size: 14px ;
  line-height: 20px;
}
.content20-wrapper .content20-text .content {
  margin-top: 20px;
}
.content20-wrapper .content20-text .ImageIcon {
  padding-right: 10px;
}
.content20-wrapper .content20-text .button1 {
  margin-top: 20px;
  border-radius: 30px;
  position: relative;
  width: 130px;
  font-size: 12px;
  color: #696969;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .content20-wrapper {
    min-height: 100vh;
  }
  .content20-wrapper .content20 {
    height: 100%;
    padding: 0 24px;
  }
  .content20-wrapper .content20 .ImageIcon {
    left: 100px;
  }
  .content20-wrapper .content20-img1 {
    height: 100%;
    top: -80px;
  }
  .content20-wrapper .content20-img1 .span {
    display: flex;
    position: fixed;
    top: 461px;
    left: 151px;
  }
  .content20-wrapper .content20-img1 .span img {
    display: block;
  }
  .content20-wrapper .content20-img1 .span1 {
    display: flex;
    position: fixed;
    top: 353px;
    left: 307px;
  }
  .content20-wrapper .content20-img1 .span1 img {
    display: block;
  }
  .content20-wrapper .content20-img1 .span2 {
    display: flex;
    position: fixed;
    top: 227px;
    left: 884px;
  }
  .content20-wrapper .content20-img1 .span2 img {
    display: block;
  }
  .content20-wrapper .content20-img1 .span3 {
    display: flex;
    position: fixed;
    top: 390px;
    left: 961px;
  }
  .content20-wrapper .content20-img1 .span3 img {
    display: block;
  }
  .content20-wrapper .content20-img1 .span4 {
    display: flex;
    position: fixed;
    top: 127px;
    left: 218px;
  }
  .content20-wrapper .content20-img1 .span4 img {
    width: 800px;
    display: block;
  }
  .content20-wrapper .content20-text {
    padding: 0 32px;
    top: 350px ;
    left: 300px;
    height: 100%;
    position: relative;
  }
  .content20-wrapper .content20-text .content20-content,
  .content20-wrapper .content20-text .content20-title {
    text-align: center;
    color: #696969;
    width: 600px;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
  }
  .content20-wrapper .content20-text .content {
    margin-top: 20px;
  }
  .content20-wrapper .content20-text .ImageIcon {
    padding-right: 10px;
  }
  .content20-wrapper .content20-text .button1 {
    margin-top: 20px;
    border-radius: 30px;
    position: relative;
    width: 130px;
    font-size: 12px;
    color: #696969;
  }
}
@media screen and (max-width: 767px) {
  .content20-wrapper {
    min-height: 1200px;
  }
  .content20-wrapper .content20 .ImageIcon {
    left: 0px;
  }
  .content20-wrapper .content20-img1 {
    height: 100%;
    top: -80px;
  }
  .content20-wrapper .content20-img1 .span {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 60px;
    left: 0px;
    width: 50%;
  }
  .content20-wrapper .content20-img1 .span1 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 60px;
    left: 0px;
    width: 50%;
  }
  .content20-wrapper .content20-img1 .span2 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 120px;
    left: 0px;
    width: 50%;
  }
  .content20-wrapper .content20-img1 .span3 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 120px;
    left: 0px;
    width: 50%;
  }
  .content20-wrapper .content20-img1 .span4 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 100px;
    left: 0px;
    width: 100%;
  }
  .content20-wrapper .content20-text {
    padding: 0 32px;
    top: 130px ;
    left: 0px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: left;
  }
  .content20-wrapper .content20-text .content20-content,
  .content20-wrapper .content20-text .content20-title {
    text-align: center;
    color: #666666;
    width: 400px;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
  }
  .content20-wrapper .content20-text .content {
    margin-top: 20px;
  }
  .content20-wrapper .content20 .button11 {
    position: relative;
    width: 80vw;
  }
  .content20-wrapper .content20 .button11 .button1 {
    border-radius: 30px;
    position: relative;
    width: 150px;
    font-size: 12px;
    color: #696969;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.teams0-wrapper {
  height: 100vh;
}
.teams0-wrapper .teams0 {
  display: flex;
  align-items: flex-end;
}
.teams0-wrapper .teams0 .banner-anim {
  width: 100%;
  height: 100%;
}
.teams0-wrapper .teams0 .banner-anim-thumb {
  position: fixed;
  top: 500px;
  left: -150px;
}
.teams0-wrapper .teams0 .banner-anim-thumb span {
  background: #e9e9e9;
  box-shadow: none;
}
.teams0-wrapper .teams0 .banner-anim-thumb span.active {
  background: #1890ff;
}
.teams0-wrapper .teams0 .queue-anim-leaving {
  position: relative !important;
}
.teams0-wrapper .teams0-banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.teams0-wrapper .teams0 .image1 {
  position: fixed;
  left: 300px;
  top: 300px;
}
.teams0-wrapper .teams0 .image {
  position: fixed;
  left: 346px;
  top: 100px;
}
.teams0-wrapper .teams0-content {
  position: fixed;
  left: 900px;
  font-size: 12px;
  color: #919191;
  text-align: center;
  width: 300px;
  margin: 8px auto;
}
.teams0-wrapper .teams0 h1 {
  color: #696969;
  position: fixed;
  top: 100px;
  left: 900px;
  font-size: 20px;
  text-align: left;
  width: 300px;
  margin: 24px auto 0;
}
.teams0-wrapper .teams0 h2 {
  color: #929292;
  position: fixed;
  top: 130px;
  left: 900px;
  font-size: 15px;
  text-align: left;
  width: 300px;
  margin: 24px auto 0;
}
.teams0-wrapper .teams0 h3 {
  color: #9c9c9c;
  position: fixed;
  top: 150px;
  left: 900px;
  font-size: 15px;
  text-align: left;
  width: 300px;
  margin: 24px auto 0;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .teams0-wrapper {
    height: 100vh;
  }
  .teams0-wrapper .teams0 {
    display: flex;
    align-items: flex-end;
  }
  .teams0-wrapper .teams0 .banner-anim {
    width: 100%;
    height: 100%;
  }
  .teams0-wrapper .teams0 .banner-anim-thumb {
    position: fixed;
    top: 320px;
    left: -150px;
  }
  .teams0-wrapper .teams0 .banner-anim-thumb span {
    background: #e9e9e9;
    box-shadow: none;
  }
  .teams0-wrapper .teams0 .banner-anim-thumb span.active {
    background: #1890ff;
  }
  .teams0-wrapper .teams0 .queue-anim-leaving {
    position: relative !important;
  }
  .teams0-wrapper .teams0-banner-user-elem {
    height: 100vh;
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  .teams0-wrapper .teams0 .image1 {
    position: fixed;
    left: 220px;
    top: 120px;
  }
  .teams0-wrapper .teams0 .image {
    position: fixed;
    left: 300px;
    top: 0px;
    width: 300px;
  }
  .teams0-wrapper .teams0-content {
    position: fixed;
    left: 900px;
    font-size: 12px;
    color: #919191;
    text-align: center;
    width: 300px;
    margin: 8px auto;
  }
  .teams0-wrapper .teams0 h1 {
    color: #696969;
    position: fixed;
    top: 0px;
    left: 750px;
    font-size: 16px;
    text-align: left;
    width: 300px;
    margin: 24px auto 0;
  }
  .teams0-wrapper .teams0 h2 {
    color: #929292;
    position: fixed;
    top: 130px;
    left: 900px;
    font-size: 15px;
    text-align: left;
    width: 300px;
    margin: 24px auto 0;
  }
  .teams0-wrapper .teams0 h3 {
    color: #9c9c9c;
    position: fixed;
    top: 50px;
    left: 750px;
    font-size: 12px;
    text-align: left;
    width: 300px;
    margin: 24px auto 0;
  }
}
@media screen and (max-width: 767px) {
  .teams0-wrapper {
    min-height: 1200px;
  }
  .teams0-wrapper .teams0 {
    display: flex;
    align-items: flex-end;
  }
  .teams0-wrapper .teams0 .banner-anim {
    width: 100%;
    height: 100%;
  }
  .teams0-wrapper .teams0 .banner-anim-thumb {
    position: relative;
    top: 0px;
    left: 0px;
  }
  .teams0-wrapper .teams0 .banner-anim-thumb span {
    background: #e9e9e9;
    box-shadow: none;
  }
  .teams0-wrapper .teams0 .banner-anim-thumb span.active {
    background: #1890ff;
  }
  .teams0-wrapper .teams0 .queue-anim-leaving {
    position: relative !important;
  }
  .teams0-wrapper .teams0-banner-user-elem {
    height: 100%;
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  .teams0-wrapper .teams0 .image {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 100px;
    left: 0px;
    width: 100%;
  }
  .teams0-wrapper .teams0-content {
    position: fixed;
    left: 900px;
    font-size: 12px;
    color: #919191;
    text-align: center;
    width: 300px;
    margin: 8px auto;
  }
  .teams0-wrapper .teams0 h1 {
    color: #696969;
    top: 130px;
    left: 0px;
    font-size: 24px;
    width: 100%;
    margin: 24px auto 0;
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
  }
  .teams0-wrapper .teams0 h2 {
    color: #929292;
    position: fixed;
    top: 130px;
    left: 900px;
    font-size: 15px;
    text-align: left;
    width: 300px;
    margin: 24px auto 0;
  }
  .teams0-wrapper .teams0 h3 {
    color: #9c9c9c;
    top: 130px;
    left: 0px;
    font-size: 15px;
    width: 100%;
    margin: 24px auto 0;
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.content26-wrapper {
  min-height: 100vh;
}
.content26-wrapper .content26 {
  height: 100%;
  padding: 0 24px;
}
.content26-wrapper .content26 .ImageIcon {
  left: 100px;
}
.content26-wrapper .content26-img1 {
  height: 100%;
  transform-origin: top;
}
.content26-wrapper .content26-img1 .zhengwen {
  width: 100px;
  font-size: 111px;
}
.content26-wrapper .content26-img1 .span {
  display: block;
  position: fixed;
  top: 420px;
  left: 600px;
}
.content26-wrapper .content26-img1 .span img {
  display: block;
}
.content26-wrapper .content26-img1 .span1 {
  display: block;
  position: fixed;
  top: 400px;
  left: 900px;
}
.content26-wrapper .content26-img1 .span1 img {
  display: block;
}
.content26-wrapper .content26-img1 .span2 {
  display: block;
  position: fixed;
  top: 400px;
  left: 500px;
}
.content26-wrapper .content26-img1 .span2 img {
  display: block;
}
.content26-wrapper .content26-img1 .span3 {
  display: block;
  position: fixed;
  top: 350px;
  left: 750px;
}
.content26-wrapper .content26-img1 .span3 img {
  display: block;
}
.content26-wrapper .content26-img1 .span5 {
  display: block;
  position: fixed;
  top: 320px;
  left: 350px;
}
.content26-wrapper .content26-img1 .span5 img {
  display: block;
}
.content26-wrapper .content26-text {
  display: flex;
  position: relative;
  justify-content: center;
  text-align: center;
  padding: 0 32px;
  top: 150px ;
  height: 100%;
}
.content26-wrapper .content26-text .content26-content,
.content26-wrapper .content26-text .content26-title {
  color: #696969;
  margin-top: 10px;
  font-size: 12px ;
  line-height: 25px;
  position: relative !important;
}
.content26-wrapper .content26-text .content26-title {
  font-size: 20px ;
  font-weight: normal;
  color: #000000;
  margin-top: 0px;
}
.content26-wrapper .content26-text .content {
  margin-top: 20px;
}
.content26-wrapper .content26-text .ImageIcon {
  padding-right: 10px;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .content26-wrapper {
    min-height: 100vh;
  }
  .content26-wrapper .content26 {
    height: 100%;
    padding: 0 24px;
  }
  .content26-wrapper .content26 .ImageIcon {
    left: 100px;
  }
  .content26-wrapper .content26-img1 {
    height: 100%;
    transform-origin: top;
  }
  .content26-wrapper .content26-img1 .zhengwen {
    width: 100px;
    font-size: 111px;
  }
  .content26-wrapper .content26-img1 .span {
    display: block;
    position: fixed;
    top: 220px;
    left: 500px;
  }
  .content26-wrapper .content26-img1 .span img {
    display: block;
  }
  .content26-wrapper .content26-img1 .span1 {
    display: block;
    position: fixed;
    top: 200px;
    left: 800px;
  }
  .content26-wrapper .content26-img1 .span1 img {
    display: block;
  }
  .content26-wrapper .content26-img1 .span2 {
    display: block;
    position: fixed;
    top: 200px;
    left: 400px;
  }
  .content26-wrapper .content26-img1 .span2 img {
    display: block;
  }
  .content26-wrapper .content26-img1 .span3 {
    display: block;
    position: fixed;
    top: 190px;
    left: 650px;
  }
  .content26-wrapper .content26-img1 .span3 img {
    display: block;
  }
  .content26-wrapper .content26-img1 .span5 {
    display: block;
    position: fixed;
    top: 120px;
    left: 250px;
  }
  .content26-wrapper .content26-img1 .span5 img {
    display: block;
  }
  .content26-wrapper .content26-text {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    padding: 0 32px;
    top: 100px ;
    height: 100%;
  }
  .content26-wrapper .content26-text .content26-content,
  .content26-wrapper .content26-text .content26-title {
    color: #696969;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 25px;
    position: relative !important;
  }
  .content26-wrapper .content26-text .content26-title {
    font-size: 20px ;
    font-weight: normal;
    color: #000000;
    margin-top: 0px;
  }
  .content26-wrapper .content26-text .content {
    margin-top: 20px;
  }
  .content26-wrapper .content26-text .ImageIcon {
    padding-right: 10px;
  }
}
@media screen and (max-width: 767px) {
  .content26-wrapper {
    min-height: 900px;
  }
  .content26-wrapper .content26-img1 {
    height: 400px;
    transform-origin: top;
  }
  .content26-wrapper .content26-img1 .span {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    width: 50%;
    top: 160px;
    left: 0px;
  }
  .content26-wrapper .content26-img1 .span img {
    display: block;
  }
  .content26-wrapper .content26-img1 .span1 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    width: 50%;
    top: 160px;
    left: 0px;
  }
  .content26-wrapper .content26-img1 .span1 img {
    display: block;
  }
  .content26-wrapper .content26-img1 .span2 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    width: 50%;
    top: 160px;
    left: 0px;
  }
  .content26-wrapper .content26-img1 .span2 img {
    display: block;
  }
  .content26-wrapper .content26-img1 .span3 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    width: 50%;
    top: 160px;
    left: 0px;
  }
  .content26-wrapper .content26-img1 .span3 img {
    display: block;
  }
  .content26-wrapper .content26-img1 .span4 {
    display: block;
    position: fixed;
    width: 145px;
    height: 115px;
    top: 550px;
    left: 50%;
  }
  .content26-wrapper .content26-img1 .span4 img {
    display: block;
  }
  .content26-wrapper .content26-img1 .span5 {
    display: block;
    position: fixed;
    width: 502px;
    height: 481px;
    top: 186px;
    left: 1000px;
  }
  .content26-wrapper .content26-img1 .span5 img {
    display: block;
  }
  .content26-wrapper .content26-ImageIcon {
    left: 100px;
  }
  .content26-wrapper .content26-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content26-wrapper .content26-text .content26-content,
  .content26-wrapper .content26-text .content26-title {
    width: 100%;
    top: auto;
  }
  .content26-wrapper .content26-text .content26-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.teams01-wrapper {
  height: 100vh;
}
.teams01-wrapper .teams01 {
  display: flex;
  align-items: flex-end;
}
.teams01-wrapper .teams01 .banner-anim {
  width: 100%;
  height: 100%;
}
.teams01-wrapper .teams01 .bodder {
  border: 1px solid;
}
.teams01-wrapper .teams01 .banner-anim-thumb {
  position: fixed;
  top: 530px;
  left: 0;
}
.teams01-wrapper .teams01 .banner-anim-thumb span {
  background: #e9e9e9;
  box-shadow: none;
}
.teams01-wrapper .teams01 .banner-anim-thumb span.active {
  background: #1890ff;
}
.teams01-wrapper .teams01 .queue-anim-leaving {
  position: relative !important;
}
.teams01-wrapper .teams01-banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.teams01-wrapper .teams01 .image {
  position: fixed;
  left: 800px;
  top: 100px;
}
.teams01-wrapper .teams01 .id1 {
  border: 3px #2b7eeb solid;
  border-radius: 10px;
}
.teams01-wrapper .teams01 .image1 {
  position: fixed;
  left: 800px;
  top: 250px;
}
.teams01-wrapper .teams01 .image2 {
  position: fixed;
  left: 800px;
  top: 400px;
}
.teams01-wrapper .teams01 .image3 {
  position: fixed;
  left: 0px;
  top: 100px;
}
.teams01-wrapper .teams01-content {
  position: fixed;
  left: 1000px;
  font-size: 12px;
  color: #cec8c8;
  text-align: center;
  width: 300px;
  margin: 8px auto;
}
.teams01-wrapper .teams01 h1 {
  color: #696969;
  position: fixed;
  top: 100px;
  left: 1000px;
  font-size: 20px;
  text-align: left;
  width: 300px;
  margin: 24px auto 0;
}
.teams01-wrapper .teams01 h2 {
  color: #929292;
  position: fixed;
  top: 135px;
  left: 1000px;
  font-size: 17px;
  text-align: left;
  width: 400px;
  margin: 24px auto 0;
}
.teams01-wrapper .teams01 h3 {
  color: #9c9c9c;
  position: fixed;
  top: 170px;
  left: 1000px;
  font-size: 14px;
  text-align: left;
  width: 300px;
  height: 250px;
  margin: 24px auto 0;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .teams01-wrapper {
    height: 100vh;
  }
  .teams01-wrapper .teams01 {
    display: flex;
    align-items: flex-end;
  }
  .teams01-wrapper .teams01 .banner-anim {
    width: 100%;
    height: 100%;
  }
  .teams01-wrapper .teams01 .bodder {
    border: 1px solid;
  }
  .teams01-wrapper .teams01 .banner-anim-thumb {
    position: fixed;
    top: 300px;
    left: 0px;
  }
  .teams01-wrapper .teams01 .banner-anim-thumb span {
    background: #e9e9e9;
    box-shadow: none;
  }
  .teams01-wrapper .teams01 .banner-anim-thumb span.active {
    background: #1890ff;
  }
  .teams01-wrapper .teams01 .queue-anim-leaving {
    position: relative !important;
  }
  .teams01-wrapper .teams01-banner-user-elem {
    height: 100%;
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  .teams01-wrapper .teams01 .image {
    position: fixed;
    left: 670px;
    top: 22px;
  }
  .teams01-wrapper .teams01 .image img {
    width: 100px;
    display: block;
  }
  .teams01-wrapper .teams01 .image1 {
    position: fixed;
    left: 670px;
    top: 118px;
    width: 200px;
  }
  .teams01-wrapper .teams01 .image1 img {
    width: 100px;
    display: block;
  }
  .teams01-wrapper .teams01 .image2 {
    position: fixed;
    left: 670px;
    top: 216px;
    width: 10px;
  }
  .teams01-wrapper .teams01 .image2 img {
    width: 100px;
    display: block;
  }
  .teams01-wrapper .teams01 .image3 {
    position: fixed;
    left: 160px;
    top: 20px;
    width: 500px;
  }
  .teams01-wrapper .teams01-content {
    position: fixed;
    left: 1000px;
    font-size: 12px;
    color: #cec8c8;
    text-align: center;
    width: 300px;
    margin: 8px auto;
  }
  .teams01-wrapper .teams01 h1 {
    color: #696969;
    position: fixed;
    top: 0px;
    left: 800px;
    font-size: 16px;
    text-align: left;
    width: 300px;
    margin: 24px auto 0;
  }
  .teams01-wrapper .teams01 h2 {
    color: #929292;
    position: fixed;
    top: 30px;
    left: 800px;
    font-size: 14px;
    text-align: left;
    width: 400px;
    margin: 24px auto 0;
  }
  .teams01-wrapper .teams01 h3 {
    color: #9c9c9c;
    position: fixed;
    top: 60px;
    left: 800px;
    font-size: 10px;
    text-align: left;
    width: 300px;
    height: 200px;
    margin: 24px auto 0;
  }
}
@media screen and (max-width: 767px) {
  .teams01-wrapper {
    min-height: 1200px;
  }
  .teams01-wrapper .teams01 {
    display: flex;
    align-items: flex-end;
  }
  .teams01-wrapper .teams01 .banner-anim {
    width: 100%;
    height: 100%;
  }
  .teams01-wrapper .teams01 .bodder {
    border: 1px solid;
  }
  .teams01-wrapper .teams01 .banner-anim-thumb {
    position: fixed;
    top: 0px;
    left: 0px;
  }
  .teams01-wrapper .teams01 .banner-anim-thumb span {
    background: #e9e9e9;
    box-shadow: none;
  }
  .teams01-wrapper .teams01 .banner-anim-thumb span.active {
    background: #1890ff;
  }
  .teams01-wrapper .teams01 .queue-anim-leaving {
    position: relative !important;
  }
  .teams01-wrapper .teams01-banner-user-elem {
    height: 100%;
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  .teams01-wrapper .teams01 .image {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 250px;
    left: 0px;
    width: 33%;
  }
  .teams01-wrapper .teams01 .image img {
    display: block;
    width: 100px;
  }
  .teams01-wrapper .teams01 .image1 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: 250px;
    left: 0px;
    width: 33%;
  }
  .teams01-wrapper .teams01 .image1 img {
    display: block;
    width: 100px;
  }
  .teams01-wrapper .teams01 .image2 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    top: 250px;
    left: 0px;
    width: 33%;
  }
  .teams01-wrapper .teams01 .image2 img {
    display: block;
    width: 100px;
  }
  .teams01-wrapper .teams01 .image3 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    top: -20px;
    left: 0px;
    width: 100%;
  }
  .teams01-wrapper .teams01-content {
    position: relative;
    display: inline-flex;
    justify-content: center;
    text-align: left;
    left: 0px;
    font-size: 12px;
    color: #919191;
    text-align: center;
    width: 300px;
    margin: 8px auto;
  }
  .teams01-wrapper .teams01 h1 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    top: 80px;
    left: 0px;
    font-size: 24px;
    text-align: left;
    width: 100%;
    margin: 24px auto 0;
  }
  .teams01-wrapper .teams01 h3 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    top: 100px;
    left: 0px;
    font-size: 15px;
    text-align: left;
    width: 100%;
    margin: 24px auto 0;
  }
  .teams01-wrapper .teams01 h2 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    top: 100px;
    left: 0px;
    font-size: 10px;
    text-align: left;
    width: 100%;
    margin: 24px auto 0;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.content29-wrapper {
  min-height: 100vh;
}
.content29-wrapper .content29 {
  height: 100%;
  padding: 0 24px;
}
.content29-wrapper .content29 .ImageIcon {
  left: 100px;
}
.content29-wrapper .content29-img1 {
  height: 100%;
  transform-origin: top;
}
.content29-wrapper .content29-img1 .span {
  display: flex;
  position: fixed;
  top: 230px;
  left: 180px;
}
.content29-wrapper .content29-img1 .span img {
  display: block;
}
.content29-wrapper .content29-img1 .span1 {
  display: flex;
  position: fixed;
  top: 500px;
  left: 270px;
}
.content29-wrapper .content29-img1 .span1 img {
  display: block;
}
.content29-wrapper .content29-img1 .span2 {
  display: flex;
  position: fixed;
  top: 310px;
  left: 630px;
}
.content29-wrapper .content29-img1 .span2 img {
  display: block;
}
.content29-wrapper .content29-img1 .span3 {
  display: flex;
  position: fixed;
  top: 430px;
  left: 950px;
}
.content29-wrapper .content29-img1 .span3 img {
  display: block;
}
.content29-wrapper .content29-img1 .span4 {
  display: flex;
  position: fixed;
  top: 227px;
  left: 238px;
}
.content29-wrapper .content29-img1 .span4 img {
  display: block;
}
.content29-wrapper .content29-text {
  height: 100%;
}
.content29-wrapper .content29-text .content29-content,
.content29-wrapper .content29-text .content29-title {
  top: 330px ;
  left: 130px;
  text-align: left;
  color: #666666;
  width: 300px;
  margin-top: 10px;
  font-size: 12px ;
  line-height: 20px;
  position: relative !important;
}
.content29-wrapper .content29-text .content29-title1 {
  top: 530px ;
  left: 300px;
  text-align: left;
  color: #666666;
  width: 300px;
  margin-top: 10px;
  font-size: 12px ;
  line-height: 20px;
  position: relative !important;
}
.content29-wrapper .content29-text .content29-title2 {
  top: 350px ;
  left: 600px;
  text-align: left;
  color: #666666;
  width: 300px;
  margin-top: 10px;
  font-size: 12px ;
  line-height: 20px;
  position: relative !important;
}
.content29-wrapper .content29-text .content29-title3 {
  top: 380px ;
  left: 900px;
  text-align: left;
  color: #666666;
  width: 300px;
  margin-top: 10px;
  font-size: 12px ;
  line-height: 20px;
  position: relative !important;
}
.content29-wrapper .content29-text .button111 {
  position: relative;
  top: 500px;
  left: 600px;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .content29-wrapper {
    min-height: 100vh;
  }
  .content29-wrapper .content29 {
    height: 100%;
    padding: 0 24px;
  }
  .content29-wrapper .content29 .ImageIcon {
    left: 100px;
  }
  .content29-wrapper .content29-img1 {
    height: 100%;
    transform-origin: top;
  }
  .content29-wrapper .content29-img1 .span {
    display: flex;
    position: fixed;
    top: 120px;
    left: 180px;
  }
  .content29-wrapper .content29-img1 .span img {
    width: 200px;
    display: block;
  }
  .content29-wrapper .content29-img1 .span1 {
    display: flex;
    position: fixed;
    top: 350px;
    left: 270px;
  }
  .content29-wrapper .content29-img1 .span1 img {
    width: 200px;
    display: block;
  }
  .content29-wrapper .content29-img1 .span2 {
    display: flex;
    position: fixed;
    top: 150px;
    left: 590px;
  }
  .content29-wrapper .content29-img1 .span2 img {
    width: 200px;
    display: block;
  }
  .content29-wrapper .content29-img1 .span3 {
    display: flex;
    position: fixed;
    top: 270px;
    left: 870px;
  }
  .content29-wrapper .content29-img1 .span3 img {
    width: 150px;
    display: block;
  }
  .content29-wrapper .content29-img1 .span4 {
    display: flex;
    position: fixed;
    top: 97px;
    left: 258px;
  }
  .content29-wrapper .content29-img1 .span4 img {
    width: 800px;
    display: block;
  }
  .content29-wrapper .content29-text {
    height: 100%;
  }
  .content29-wrapper .content29-text .content29-content,
  .content29-wrapper .content29-text .content29-title {
    top: 180px ;
    left: 130px;
    text-align: left;
    color: #666666;
    width: 300px;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
    position: relative !important;
  }
  .content29-wrapper .content29-text .content29-title1 {
    top: 370px ;
    left: 280px;
    text-align: left;
    color: #666666;
    width: 300px;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
    position: relative !important;
  }
  .content29-wrapper .content29-text .content29-title2 {
    top: 150px ;
    left: 550px;
    text-align: left;
    color: #666666;
    width: 300px;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
    position: relative !important;
  }
  .content29-wrapper .content29-text .content29-title3 {
    top: 210px ;
    left: 800px;
    text-align: left;
    color: #666666;
    width: 300px;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
    position: relative !important;
  }
  .content29-wrapper .content29-text .button111 {
    position: relative;
    top: 260px;
    left: 600px;
  }
}
@media screen and (max-width: 767px) {
  .content29-wrapper {
    min-height: 100vh;
  }
  .content29-wrapper .content29-img1 {
    height: 100%;
    transform-origin: top;
  }
  .content29-wrapper .content29-img1 .span {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    top: 100px;
    left: 0px;
  }
  .content29-wrapper .content29-img1 .span img {
    display: block;
  }
  .content29-wrapper .content29-img1 .span1 {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    top: 140px;
    left: 0px;
  }
  .content29-wrapper .content29-img1 .span1 img {
    display: block;
  }
  .content29-wrapper .content29-img1 .span2 {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    top: 180px;
    left: 0px;
  }
  .content29-wrapper .content29-img1 .span2 img {
    display: block;
  }
  .content29-wrapper .content29-img1 .span3 {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    top: 220px;
    left: 0px;
  }
  .content29-wrapper .content29-img1 .span3 img {
    display: block;
  }
  .content29-wrapper .content29-img1 .span4 {
    display: flex;
    position: fixed;
    top: 227px;
    left: 10000px;
  }
  .content29-wrapper .content29-img1 .span4 img {
    display: block;
  }
  .content29-wrapper .content29-text .content29-title {
    text-align: center;
    top: 180px ;
    left: 10000px;
    color: #666666;
    width: 100vw;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
  }
  .content29-wrapper .content29-text .content29-title1 {
    display: flex;
    position: relative;
    text-align: center;
    top: 290px ;
    left: 10000px;
    color: #666666;
    width: 100vw;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
  }
  .content29-wrapper .content29-text .content29-title2 {
    top: 450px ;
    left: 10000px;
    color: #666666;
    width: 100vw;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
  }
  .content29-wrapper .content29-text .content29-title3 {
    top: 600px;
    left: 10000px;
    color: #666666;
    width: 100vw;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.content31-wrapper {
  min-height: 100vh;
}
.content31-wrapper .content31 {
  height: 100%;
  padding: 0 24px;
}
.content31-wrapper .content31 .ImageIcon {
  left: 100px;
}
.content31-wrapper .content31-img1 {
  height: 100%;
  transform-origin: top;
}
.content31-wrapper .content31-img1 span {
  display: flex;
  position: relative;
  top: 187px;
  left: 300px;
}
.content31-wrapper .content31-img1 span img {
  display: block;
}
.content31-wrapper .content31-text {
  padding: 0 32px;
  top: 300px ;
  height: 100%;
}
.content31-wrapper .content31-text .content31-content,
.content31-wrapper .content31-text .content31-title {
  color: #666666;
  width: 300px;
  margin-top: 10px;
  font-size: 12px ;
  line-height: 20px;
  position: relative !important;
}
.content31-wrapper .content31-text .content31-title {
  font-size: 20px ;
  font-weight: normal;
  color: #000000;
  margin-top: 0px;
}
.content31-wrapper .content31-text .content {
  margin-top: 20px;
}
.content31-wrapper .content31-text .ImageIcon {
  padding-right: 10px;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .content31-wrapper {
    min-height: 100vh;
  }
  .content31-wrapper .content31 {
    height: 100%;
    padding: 0 24px;
  }
  .content31-wrapper .content31 .ImageIcon {
    left: 100px;
  }
  .content31-wrapper .content31-img1 {
    height: 100%;
    transform-origin: top;
  }
  .content31-wrapper .content31-img1 span {
    display: flex;
    position: relative;
    top: 100px;
    left: 300px;
  }
  .content31-wrapper .content31-img1 span img {
    width: 500px;
    display: block;
  }
  .content31-wrapper .content31-text {
    padding: 0 32px;
    top: 170px ;
    position: relative;
    left: -100px;
    height: 100%;
  }
  .content31-wrapper .content31-text .content31-content,
  .content31-wrapper .content31-text .content31-title {
    color: #666666;
    width: 300px;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
    position: relative !important;
  }
  .content31-wrapper .content31-text .content31-title {
    font-size: 20px ;
    font-weight: normal;
    color: #000000;
    margin-top: 0px;
  }
  .content31-wrapper .content31-text .content {
    margin-top: 20px;
  }
  .content31-wrapper .content31-text .ImageIcon {
    padding-right: 10px;
  }
}
@media screen and (max-width: 767px) {
  .content31-wrapper {
    min-height: 1200px;
  }
  .content31-wrapper .content31-img1 {
    height: 300px;
    transform-origin: top;
  }
  .content31-wrapper .content31-img1 span {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    top: 50px;
    left: 0px;
  }
  .content31-wrapper .content31-img1 span img {
    display: block;
  }
  .content31-wrapper .content31-ImageIcon {
    left: 100px;
  }
  .content31-wrapper .content31-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content31-wrapper .content31-text .content31-content,
  .content31-wrapper .content31-text .content31-title {
    width: 100%;
    top: auto;
  }
  .content31-wrapper .content31-text .content31-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.content24-wrapper {
  min-height: 100vh;
}
.content24-wrapper .content24 {
  height: 100%;
  padding: 0 24px;
}
.content24-wrapper .content24 .ImageIcon {
  left: 100px;
}
.content24-wrapper .content24-img1 {
  height: 100%;
  transform-origin: top;
}
.content24-wrapper .content24-img1 .box1 {
  position: fixed;
  top: 10px;
  left: 212px;
  font-size: 30px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 456px;
  height: 123px;
  font-size: #ecebeb;
}
.content24-wrapper .content24-img1 .box2 {
  position: fixed;
  font-size: 30px;
  top: 10px;
  left: 678px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 547px;
  height: 265px;
}
.content24-wrapper .content24-img1 .box3 {
  position: fixed;
  top: 143px;
  left: 212px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 219px;
  height: 131px;
}
.content24-wrapper .content24-img1 .box4 {
  position: fixed;
  top: 143px;
  left: 442px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 219px;
  height: 131px;
}
.content24-wrapper .content24-img1 .box5 {
  position: fixed;
  top: 284px;
  font-size: 30px;
  left: 212px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 457px;
  height: 265px;
}
.content24-wrapper .content24-img1 .box6 {
  position: fixed;
  font-size: 30px;
  top: 284px;
  left: 678px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 547px;
  height: 265px;
}
.content24-wrapper .content24-img1 .box7 {
  font-size: 30px;
  position: fixed;
  top: 559px;
  left: 212px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 303px;
  height: 225px;
}
.content24-wrapper .content24-img1 .box8 {
  font-size: 30px;
  position: fixed;
  top: 559px;
  left: 525px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 303px;
  height: 225px;
}
.content24-wrapper .content24-img1 .box9 {
  position: fixed;
  top: 559px;
  left: 838px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 185px;
  height: 105px;
}
.content24-wrapper .content24-img1 .box10 {
  position: fixed;
  top: 674px;
  left: 838px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 185px;
  height: 110px;
}
.content24-wrapper .content24-img1 .box11 {
  position: fixed;
  top: 559px;
  left: 1033px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 192px;
  height: 105px;
}
.content24-wrapper .content24-img1 .box12 {
  position: fixed;
  top: 674px;
  left: 1033px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 192px;
  height: 110px;
}
.content24-wrapper .content24-img1 .box13 {
  position: fixed;
  top: 424px;
  left: 1235px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 205px;
  height: 125px;
}
.content24-wrapper .content24-img1 .box14 {
  position: fixed;
  top: 12px;
  left: 1px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 200px;
  height: 123px;
}
.content24-wrapper .content24-img1 .box15 {
  position: fixed;
  top: 144px;
  left: 1px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 200px;
  height: 403px;
}
.content24-wrapper .content24-img1 .box16 {
  position: fixed;
  top: 559px;
  left: 1px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 200px;
  height: 351px;
}
.content24-wrapper .content24-img1 .box17 {
  position: fixed;
  top: 12px;
  left: 1235px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 205px;
  height: 403px;
}
.content24-wrapper .content24-img1 .box18 {
  position: fixed;
  top: 559px;
  left: 1235px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  width: 205px;
  height: 351px;
}
.content24-wrapper .content24-text {
  padding: 0 32px;
  top: 600px ;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  text-align: center;
}
.content24-wrapper .content24-text .content24-content,
.content24-wrapper .content24-text .content24-title {
  text-align: center;
  color: #666666;
  width: 400px;
  margin-top: 10px;
  font-size: 12px ;
  line-height: 20px;
  position: relative !important;
}
.content24-wrapper .content24-text .content {
  margin-top: 20px;
}
.content24-wrapper .content24-text .ImageIcon {
  padding-right: 10px;
}
.content24-wrapper .content24 .button1 {
  margin-top: 20px;
  border-radius: 30px;
  position: relative;
  justify-content: center;
  text-align: center;
  width: 130px;
  font-size: 12px;
  color: black;
  background: #F6F6F6;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .content24-wrapper {
    min-height: 100vh;
  }
  .content24-wrapper .content24 {
    height: 100%;
    padding: 0 24px;
  }
  .content24-wrapper .content24 .ImageIcon {
    left: 100px;
  }
  .content24-wrapper .content24-img1 {
    height: 100%;
    transform-origin: top;
  }
  .content24-wrapper .content24-img1 .box1 {
    position: fixed;
    top: 0px;
    left: 176px;
    font-size: 20px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 381px;
    height: 103px;
    font-size: #ecebeb;
  }
  .content24-wrapper .content24-img1 .box2 {
    position: fixed;
    font-size: 20px;
    top: 0px;
    left: 565px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 456px;
    height: 221px;
  }
  .content24-wrapper .content24-img1 .box3 {
    position: fixed;
    top: 110px;
    left: 176px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 184px;
    height: 110px;
  }
  .content24-wrapper .content24-img1 .box4 {
    position: fixed;
    top: 110px;
    left: 368px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 189px;
    height: 110px;
  }
  .content24-wrapper .content24-img1 .box5 {
    position: fixed;
    font-size: 20px;
    top: 228px;
    left: 176px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 382px;
    height: 150px;
  }
  .content24-wrapper .content24-img1 .box6 {
    position: fixed;
    font-size: 20px;
    top: 228px;
    left: 565px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 456px;
    height: 150px;
  }
  .content24-wrapper .content24-img1 .box7 {
    font-size: 20px;
    position: fixed;
    top: 385px;
    left: 176px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 254px;
    height: 140px;
  }
  .content24-wrapper .content24-img1 .box8 {
    font-size: 20px;
    position: fixed;
    top: 385px;
    left: 437px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 253px;
    height: 140px;
  }
  .content24-wrapper .content24-img1 .box9 {
    font-size: 6px;
    position: fixed;
    top: 385px;
    left: 698px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 155px;
    height: 70px;
  }
  .content24-wrapper .content24-img1 .box10 {
    font-size: 6px;
    position: fixed;
    top: 385px;
    left: 860px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 161px;
    height: 70px;
  }
  .content24-wrapper .content24-img1 .box11 {
    position: fixed;
    top: 460px;
    left: 698px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 155px;
    height: 70px;
  }
  .content24-wrapper .content24-img1 .box12 {
    position: fixed;
    top: 460px;
    left: 860px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 155px;
    height: 70px;
  }
  .content24-wrapper .content24-img1 .box13 {
    position: fixed;
    top: 273px;
    left: 1029px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 171px;
    height: 105px;
  }
  .content24-wrapper .content24-img1 .box14 {
    position: fixed;
    top: 111px;
    left: 0px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 168px;
    height: 337px;
  }
  .content24-wrapper .content24-img1 .box15 {
    position: fixed;
    top: 111px;
    left: 0px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 168px;
    height: 337px;
  }
  .content24-wrapper .content24-img1 .box16 {
    position: fixed;
    top: 457px;
    left: 0px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 168px;
    height: 337px;
  }
  .content24-wrapper .content24-img1 .box17 {
    position: fixed;
    top: 0px;
    left: 1029px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 168px;
    height: 267px;
  }
  .content24-wrapper .content24-img1 .box18 {
    position: fixed;
    top: 385px;
    left: 1029px;
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    width: 171px;
    height: 351px;
  }
  .content24-wrapper .content24-text {
    padding: 0 32px;
    top: 600px ;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
  }
  .content24-wrapper .content24-text .content24-content,
  .content24-wrapper .content24-text .content24-title {
    text-align: center;
    color: #666666;
    width: 400px;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 20px;
    position: relative !important;
  }
  .content24-wrapper .content24-text .content {
    margin-top: 20px;
  }
  .content24-wrapper .content24-text .ImageIcon {
    padding-right: 10px;
  }
  .content24-wrapper .content24 .button1 {
    margin-top: 20px;
    border-radius: 30px;
    position: relative;
    justify-content: center;
    text-align: center;
    width: 130px;
    font-size: 12px;
    color: black;
    background: #F6F6F6;
  }
}
@media screen and (max-width: 767px) {
  .content24-wrapper {
    min-height: 1200px;
  }
  .content24-wrapper .content24-img1 {
    height: 300px;
    transform-origin: top;
  }
  .content24-wrapper .content24-img1 span {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    top: 50px;
    left: 0px;
  }
  .content24-wrapper .content24-img1 span img {
    display: block;
  }
  .content24-wrapper .content24-ImageIcon {
    left: 100px;
  }
  .content24-wrapper .content24-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content24-wrapper .content24-text .content24-content,
  .content24-wrapper .content24-text .content24-title {
    width: 100%;
    top: auto;
  }
  .content24-wrapper .content24-text .content24-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.teams02-wrapper {
  height: 100vh;
}
.teams02-wrapper .teams02 .demo {
  position: relative;
  display: inline-flex;
}
.teams02-wrapper .teams02 .demo1 {
  position: relative;
  top: 70px;
  left: 300px;
}
.teams02-wrapper .teams02 .demo-list-container {
  position: relative;
  top: 150px;
  left: 220px;
}
.teams02-wrapper .teams02 .name {
  color: #696969;
  font-size: 30px;
  margin-right: 20px;
}
.teams02-wrapper .teams02 .job {
  color: #929292;
  font-size: 17px;
}
.teams02-wrapper .teams02 .introduce {
  color: #9c9c9c;
  font-size: 14px;
  line-height: 3.2em;
  width: 663px;
  height: 500px;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .teams02-wrapper {
    height: 100vh;
  }
  .teams02-wrapper .teams02 .demo {
    position: relative;
    display: inline-flex;
  }
  .teams02-wrapper .teams02 .demo1 {
    position: relative;
    top: 00px;
    left: 300px;
  }
  .teams02-wrapper .teams02 .demo-list-container {
    position: relative;
    top: 0px;
    left: 220px;
  }
  .teams02-wrapper .teams02 .name {
    color: #696969;
    font-size: 20px;
    margin-right: 20px;
  }
  .teams02-wrapper .teams02 .job {
    color: #929292;
    font-size: 14px;
  }
  .teams02-wrapper .teams02 .introduce {
    color: #9c9c9c;
    font-size: 12px;
    line-height: 3.2em;
    width: 663px;
    height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .teams02-wrapper {
    min-height: 1200px;
  }
  .teams02-wrapper .teams02 .demo {
    position: relative;
    display: inline-flex;
  }
  .teams02-wrapper .teams02 .demo1 {
    position: relative;
    top: 70px;
    left: 30px;
  }
  .teams02-wrapper .teams02 .demo-list-container {
    position: relative;
    top: 150px;
    left: 0px;
  }
  .teams02-wrapper .teams02 .name {
    color: #666666;
    font-size: 30px;
    margin-right: 20px;
  }
  .teams02-wrapper .teams02 .job {
    color: #666666;
    font-size: 16px;
  }
  .teams02-wrapper .teams02 .introduce {
    color: #666666;
    font-size: 14px;
    line-height: 3.2em;
    width: 200px;
    height: 500px;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.teams03-wrapper {
  height: 100vh;
}
.teams03-wrapper .teams03 .demo {
  position: relative;
  display: inline-flex;
}
.teams03-wrapper .teams03 .demo1 {
  position: relative;
  top: 70px;
  left: 300px;
}
.teams03-wrapper .teams03 .demo-list-container {
  position: relative;
  top: 150px;
  left: 220px;
}
.teams03-wrapper .teams03 .name {
  color: #696969;
  font-size: 30px;
  margin-right: 20px;
}
.teams03-wrapper .teams03 .job {
  color: #929292;
  font-size: 17px;
}
.teams03-wrapper .teams03 .introduce {
  color: #9c9c9c;
  font-size: 14px;
  line-height: 3.2em;
  width: 663px;
  height: 500px;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .teams03-wrapper {
    height: 100vh;
  }
  .teams03-wrapper .teams03 .demo {
    position: relative;
    display: inline-flex;
  }
  .teams03-wrapper .teams03 .demo1 {
    position: relative;
    top: 00px;
    left: 300px;
  }
  .teams03-wrapper .teams03 .demo-list-container {
    position: relative;
    top: 0px;
    left: 220px;
  }
  .teams03-wrapper .teams03 .name {
    color: #696969;
    font-size: 20px;
    margin-right: 20px;
  }
  .teams03-wrapper .teams03 .job {
    color: #929292;
    font-size: 14px;
  }
  .teams03-wrapper .teams03 .introduce {
    color: #9c9c9c;
    font-size: 12px;
    line-height: 3.2em;
    width: 663px;
    height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .teams03-wrapper {
    min-height: 1200px;
  }
  .teams03-wrapper .teams03 .demo {
    position: relative;
    display: inline-flex;
  }
  .teams03-wrapper .teams03 .demo1 {
    position: relative;
    top: 70px;
    left: 30px;
  }
  .teams03-wrapper .teams03 .demo-list-container {
    position: relative;
    top: 150px;
    left: 0px;
  }
  .teams03-wrapper .teams03 .name {
    color: #666666;
    font-size: 30px;
    margin-right: 20px;
  }
  .teams03-wrapper .teams03 .job {
    color: #666666;
    font-size: 16px;
  }
  .teams03-wrapper .teams03 .introduce {
    color: #666666;
    font-size: 14px;
    line-height: 3.2em;
    width: 200px;
    height: 500px;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.teams04-wrapper {
  height: 100vh;
}
.teams04-wrapper .teams04 .demo {
  position: relative;
  display: inline-flex;
}
.teams04-wrapper .teams04 .demo1 {
  position: relative;
  top: 70px;
  left: 300px;
}
.teams04-wrapper .teams04 .demo-list-container {
  position: relative;
  top: 150px;
  left: 220px;
}
.teams04-wrapper .teams04 .name {
  color: #696969;
  font-size: 30px;
  margin-right: 20px;
}
.teams04-wrapper .teams04 .job {
  color: #929292;
  font-size: 17px;
}
.teams04-wrapper .teams04 .introduce {
  color: #9c9c9c;
  font-size: 14px;
  line-height: 3.2em;
  width: 663px;
  height: 500px;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .teams04-wrapper {
    height: 100vh;
  }
  .teams04-wrapper .teams04 .demo {
    position: relative;
    display: inline-flex;
  }
  .teams04-wrapper .teams04 .demo1 {
    position: relative;
    top: 00px;
    left: 300px;
  }
  .teams04-wrapper .teams04 .demo-list-container {
    position: relative;
    top: 0px;
    left: 220px;
  }
  .teams04-wrapper .teams04 .name {
    color: #696969;
    font-size: 20px;
    margin-right: 20px;
  }
  .teams04-wrapper .teams04 .job {
    color: #929292;
    font-size: 14px;
  }
  .teams04-wrapper .teams04 .introduce {
    color: #9c9c9c;
    font-size: 12px;
    line-height: 3.2em;
    width: 663px;
    height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .teams04-wrapper {
    min-height: 1200px;
  }
  .teams04-wrapper .teams04 .demo {
    position: relative;
    display: inline-flex;
  }
  .teams04-wrapper .teams04 .demo1 {
    position: relative;
    top: 70px;
    left: 30px;
  }
  .teams04-wrapper .teams04 .demo-list-container {
    position: relative;
    top: 150px;
    left: 0px;
  }
  .teams04-wrapper .teams04 .name {
    color: #666666;
    font-size: 30px;
    margin-right: 20px;
  }
  .teams04-wrapper .teams04 .job {
    color: #666666;
    font-size: 16px;
  }
  .teams04-wrapper .teams04 .introduce {
    color: #666666;
    font-size: 14px;
    line-height: 3.2em;
    width: 200px;
    height: 500px;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.teams05-wrapper {
  height: 100vh;
}
.teams05-wrapper .teams05 .demo {
  position: relative;
  display: inline-flex;
}
.teams05-wrapper .teams05 .demo1 {
  position: relative;
  top: 70px;
  left: 300px;
}
.teams05-wrapper .teams05 .demo-list-container {
  position: relative;
  top: 150px;
  left: 220px;
}
.teams05-wrapper .teams05 .name {
  color: #696969;
  font-size: 30px;
  margin-right: 20px;
}
.teams05-wrapper .teams05 .job {
  color: #929292;
  font-size: 17px;
}
.teams05-wrapper .teams05 .introduce {
  color: #9c9c9c;
  font-size: 14px;
  line-height: 3.2em;
  width: 663px;
  height: 500px;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .teams05-wrapper {
    height: 100vh;
  }
  .teams05-wrapper .teams05 .demo {
    position: relative;
    display: inline-flex;
  }
  .teams05-wrapper .teams05 .demo1 {
    position: relative;
    top: 00px;
    left: 300px;
  }
  .teams05-wrapper .teams05 .demo-list-container {
    position: relative;
    top: 0px;
    left: 220px;
  }
  .teams05-wrapper .teams05 .name {
    color: #696969;
    font-size: 20px;
    margin-right: 20px;
  }
  .teams05-wrapper .teams05 .job {
    color: #929292;
    font-size: 14px;
  }
  .teams05-wrapper .teams05 .introduce {
    color: #9c9c9c;
    font-size: 12px;
    line-height: 3.2em;
    width: 663px;
    height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .teams05-wrapper {
    min-height: 1200px;
  }
  .teams05-wrapper .teams05 .demo {
    position: relative;
    display: inline-flex;
  }
  .teams05-wrapper .teams05 .demo1 {
    position: relative;
    top: 70px;
    left: 30px;
  }
  .teams05-wrapper .teams05 .demo-list-container {
    position: relative;
    top: 150px;
    left: 0px;
  }
  .teams05-wrapper .teams05 .name {
    color: #666666;
    font-size: 30px;
    margin-right: 20px;
  }
  .teams05-wrapper .teams05 .job {
    color: #666666;
    font-size: 16px;
  }
  .teams05-wrapper .teams05 .introduce {
    color: #666666;
    font-size: 14px;
    line-height: 3.2em;
    width: 200px;
    height: 500px;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.content61-wrapper {
  min-height: 100vh;
}
.content61-wrapper .content61 {
  height: 100%;
  padding: 0 24px;
}
.content61-wrapper .content61 .ImageIcon {
  left: 100px;
}
.content61-wrapper .content61-img1 {
  height: 100%;
  transform-origin: top;
}
.content61-wrapper .content61-img1 .span {
  display: block;
  position: fixed;
  width: 145px;
  height: 115px;
  top: 529px;
  left: 223px;
}
.content61-wrapper .content61-img1 .span img {
  display: block;
}
.content61-wrapper .content61-img1 .span1 {
  display: block;
  position: fixed;
  width: 103px;
  height: 79px;
  top: 276px;
  left: 421px;
}
.content61-wrapper .content61-img1 .span1 img {
  display: block;
}
.content61-wrapper .content61-img1 .span2 {
  display: block;
  position: fixed;
  width: 116px;
  height: 89px;
  top: 450px;
  left: 460px;
}
.content61-wrapper .content61-img1 .span2 img {
  display: block;
}
.content61-wrapper .content61-img1 .span3 {
  display: block;
  position: fixed;
  width: 118px;
  height: 101px;
  top: 576px;
  left: 597px;
}
.content61-wrapper .content61-img1 .span3 img {
  display: block;
}
.content61-wrapper .content61-img1 .span4 {
  display: block;
  position: fixed;
  width: 145px;
  height: 115px;
  top: 346px;
  left: 702px;
}
.content61-wrapper .content61-img1 .span4 img {
  display: block;
}
.content61-wrapper .content61-img1 .span5 {
  display: block;
  position: fixed;
  width: 502px;
  height: 481px;
  top: 186px;
  left: 286px;
}
.content61-wrapper .content61-img1 .span5 img {
  display: block;
}
.content61-wrapper .content61-text {
  padding: 0 32px;
  top: 300px ;
  height: 100%;
}
.content61-wrapper .content61-text .content61-content,
.content61-wrapper .content61-text .content61-title {
  color: #727070;
  margin-top: 10px;
  font-size: 14px ;
  line-height: 25px;
  position: relative !important;
}
.content61-wrapper .content61-text .content61-title {
  font-size: 20px ;
  font-weight: normal;
  color: #696969;
  margin-top: 0px;
}
.content61-wrapper .content61-text .content {
  margin-top: 20px;
}
.content61-wrapper .content61-text .ImageIcon {
  padding-right: 10px;
}
@media screen and (max-width: 1280px) and (min-width: 800px) {
  .content61-wrapper {
    min-height: 100vh;
  }
  .content61-wrapper .content61 {
    height: 100%;
    padding: 0 24px;
  }
  .content61-wrapper .content61 .ImageIcon {
    left: 100px;
  }
  .content61-wrapper .content61-img1 {
    height: 100%;
    transform-origin: top;
  }
  .content61-wrapper .content61-img1 .span {
    display: block;
    position: fixed;
    width: 145px;
    height: 115px;
    top: 359px;
    left: 183px;
  }
  .content61-wrapper .content61-img1 .span img {
    display: block;
  }
  .content61-wrapper .content61-img1 .span1 {
    display: block;
    position: fixed;
    width: 103px;
    height: 79px;
    top: 180px;
    left: 331px;
  }
  .content61-wrapper .content61-img1 .span1 img {
    display: block;
  }
  .content61-wrapper .content61-img1 .span2 {
    display: block;
    position: fixed;
    width: 116px;
    height: 89px;
    top: 310px;
    left: 420px;
  }
  .content61-wrapper .content61-img1 .span2 img {
    display: block;
  }
  .content61-wrapper .content61-img1 .span3 {
    display: block;
    position: fixed;
    width: 118px;
    height: 101px;
    top: 406px;
    left: 557px;
  }
  .content61-wrapper .content61-img1 .span3 img {
    display: block;
  }
  .content61-wrapper .content61-img1 .span4 {
    display: block;
    position: fixed;
    width: 145px;
    height: 115px;
    top: 176px;
    left: 562px;
  }
  .content61-wrapper .content61-img1 .span4 img {
    display: block;
  }
  .content61-wrapper .content61-img1 .span5 {
    display: block;
    position: fixed;
    width: 502px;
    height: 481px;
    top: 116px;
    left: 286px;
  }
  .content61-wrapper .content61-img1 .span5 img {
    width: 400px;
    display: block;
  }
  .content61-wrapper .content61-text {
    padding: 0 32px;
    top: 140px ;
    height: 100%;
    position: relative;
    left: -100px;
  }
  .content61-wrapper .content61-text .content61-content,
  .content61-wrapper .content61-text .content61-title {
    color: #727070;
    margin-top: 10px;
    font-size: 12px ;
    line-height: 25px;
    position: relative !important;
  }
  .content61-wrapper .content61-text .content61-title {
    font-size: 16px ;
    font-weight: normal;
    color: #696969;
    margin-top: 0px;
  }
  .content61-wrapper .content61-text .content {
    margin-top: 20px;
  }
  .content61-wrapper .content61-text .ImageIcon {
    padding-right: 10px;
  }
}
@media screen and (max-width: 767px) {
  .content61-wrapper {
    min-height: 1200px;
  }
  .content61-wrapper .content61-img1 {
    height: 400px;
    transform-origin: top;
  }
  .content61-wrapper .content61-img1 .span {
    display: block;
    position: fixed;
    width: 145px;
    height: 115px;
    top: 30px;
    left: 50%;
  }
  .content61-wrapper .content61-img1 .span img {
    display: block;
  }
  .content61-wrapper .content61-img1 .span1 {
    display: block;
    position: fixed;
    width: 145px;
    height: 115px;
    top: 160px;
    left: 10%;
  }
  .content61-wrapper .content61-img1 .span1 img {
    display: block;
  }
  .content61-wrapper .content61-img1 .span2 {
    display: block;
    position: fixed;
    width: 145px;
    height: 115px;
    top: 290px;
    left: 50%;
  }
  .content61-wrapper .content61-img1 .span2 img {
    display: block;
  }
  .content61-wrapper .content61-img1 .span3 {
    display: block;
    position: fixed;
    width: 145px;
    height: 115px;
    top: 420px;
    left: 10%;
  }
  .content61-wrapper .content61-img1 .span3 img {
    display: block;
  }
  .content61-wrapper .content61-img1 .span4 {
    display: block;
    position: fixed;
    width: 145px;
    height: 115px;
    top: 550px;
    left: 50%;
  }
  .content61-wrapper .content61-img1 .span4 img {
    display: block;
  }
  .content61-wrapper .content61-img1 .span5 {
    display: block;
    position: fixed;
    width: 502px;
    height: 481px;
    top: 186px;
    left: 1000px;
  }
  .content61-wrapper .content61-img1 .span5 img {
    display: block;
  }
  .content61-wrapper .content61-ImageIcon {
    left: 100px;
  }
  .content61-wrapper .content61-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content61-wrapper .content61-text .content61-content,
  .content61-wrapper .content61-text .content61-title {
    width: 100%;
    top: auto;
  }
  .content61-wrapper .content61-text .content61-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}

